import {
  instance as axiosDefault,
  instanceAuth as axios,
} from "../conf/axios.default";
import jwt from "jwt-decode";

export const isAuthenticated = () => {
  const token = window.sessionStorage.getItem("Token");
  return !!token;
};

export const isFullversion = () => {
  try {
    const token = window.sessionStorage.getItem("Token");
    if (!token) return false;

    const decoded: any = jwt(token);
    return decoded && decoded.isFullversion;
  } catch (ex) {
    console.error(ex);
    return false;
  }
};

export const getToken = () => {
  return window.sessionStorage.getItem("Token");
};

export const storeToken = (token: string) => {
  //todo, potentially reaplce bearer etc
  window.sessionStorage.setItem("Token", token);
};

export const loginPartner = async (
  partnerId: string,
  partnerPassword: string
) => {
  try {
    const data = {
      partnerId: partnerId,
      partnerPassword: partnerPassword,
    };

    let res = await axios.post("/loginPartner", data);
    return res.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const performNafiSSO = async () => {
  try {
    let res = await axiosDefault.post("/nafi/sso");
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loginEmployee = async (
  partnerId: string,
  partnerPassword: string,
  employeeId: string,
  employeePassword: string
) => {
  try {
    const data = {
      partnerId: partnerId,
      partnerPassword: partnerPassword,
      employeeId: employeeId,
      employeePassword: employeePassword,
    };

    let res = await axios.post("/login", data);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loginMVPToken = async (token?: string, mvpToken?: string) => {
  try {
    const data = {
      token,
      mvpToken,
    };
    const res = await axios.post("/loginMVPToken", data);
    //let res = await axios.post("/login", data);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const logout = () => {
  window.sessionStorage.removeItem("Token");
};
