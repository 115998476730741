import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  makeStyles,
  TextField,
  Button,
  Container,
  CssBaseline,
  Avatar,
  Typography,
} from "@material-ui/core";

import useLocalStorage from "./hooks/useLocalStorage";
import Loading from "./Loading";
import { useState, useEffect } from "react";
import React from "react";
import Alert from "./Alert";
import { LockOutlined } from "@material-ui/icons";
import {
  getToken,
  isAuthenticated,
  loginEmployee,
  loginPartner,
  loginMVPToken,
  performNafiSSO,
  storeToken,
} from "./util/Auth";

const useStyles = makeStyles((theme: any) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 1),
  },
}));

export default function Login() {
  const classes = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  const token = isAuthenticated() ? getToken() : urlParams.get("token");
  const mvpToken = urlParams.get("mvpToken");
  const customerId = urlParams.get("customerId");

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [employees, setEmployees] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isPartnerLoggedIn, setIsPartnerLoggedIn] = useState(false);
  const [isExecutingNafi, setIsExecutingNafi] = useState(false);

  const [partnerId, setPartnerId] = useState("");
  const [employeeId, setEmployeeId] = useLocalStorage("employeeId", "");
  const [partnerPassword, setPartnerPassword] = useState("");
  const [employeePassword, setEmployeePassword] = useState("");

  useEffect(() => {
    if (!!token) {
      setIsLoading(true);
      setShowError(false);
      setErrorText("");

      _performNafiSSO();
    } else if (!!mvpToken) {
      setIsLoading(true);
      setShowError(false);
      setErrorText("");

      _loginMVPToken(mvpToken);
    }
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    setShowError(false);
    setErrorText("");

    try {
      if (isPartnerLoggedIn) _loginEmployee();
      else _loginPartner();
    } catch (ex) {
      console.error(ex);
      setShowError(true);
      setIsLoading(false);
    }
  };

  const cancel = () => {
    setIsPartnerLoggedIn(false);
  };

  const _loginPartner = async () => {
    let res = await loginPartner(partnerId, partnerPassword);

    if (res.token) {
      storeToken(res.token);
      _performNafiSSO();
    } else {
      const partnerEmployees = res.employees;
      if (!partnerEmployees || partnerEmployees.length === 0) {
        setErrorText("Makler-Id oder Passwort nicht korrekt!");
        setShowError(true);
        setIsLoading(false);
      } else {
        //display employee login
        setIsPartnerLoggedIn(true);
        setEmployees(partnerEmployees);
        setIsLoading(false);
      }
    }
  };

  const _loginEmployee = async () => {
    let res = await loginEmployee(
      partnerId,
      partnerPassword,
      employeeId,
      employeePassword
    );

    if (res.token) {
      storeToken(res.token);
      _performNafiSSO();
    } else {
      setErrorText("Mitarbeiter Passwort nicht korrekt!");
      setShowError(true);
      setIsLoading(false);
    }
  };

  const _loginMVPToken = async (mvpToken: string) => {
    try {
      const res = await loginMVPToken(mvpToken);

      if (res.token) {
        storeToken(res.token);
        _performNafiSSO();
      } else {
        setErrorText(
          "Das Token ist ungültig, bitte kontaktieren Sie Ihren Kontakt bei der KAB!"
        );
        setShowError(true);
        setIsLoading(false);
      }
    } catch (ex) {
      setErrorText(
        "Das Token ist ungültig, bitte kontaktieren Sie Ihren Kontakt bei der KAB!"
      );
      setShowError(true);
      setIsLoading(false);
    }
  };

  const _performNafiSSO = async () => {
    setIsExecutingNafi(true);
    const data = await performNafiSSO();
    window.location.href = data.link;
    setIsExecutingNafi(false);
  };

  const employeeLogin = (
    <>
      <FormControl fullWidth required>
        <InputLabel id="employeeId-label">Mitarbeiterkürzel</InputLabel>

        <Select
          id="employeeId"
          fullWidth
          labelId="employeeId-label"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        >
          {employees.map((employee: any) => (
            <MenuItem key={employee.id} value={employee.shortName}>
              {employee.lastName}, {employee.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        margin="normal"
        required
        fullWidth
        name="employeePassword"
        label="Mitarbeiter Passwort"
        type="password"
        id="employeePassword"
        autoComplete="current-password"
        onChange={(e: any) => setEmployeePassword(e.target.value)}
        value={employeePassword}
      />
    </>
  );
  const partnerLogin = (
    <>
      <TextField
        margin="normal"
        disabled={isPartnerLoggedIn}
        required
        fullWidth
        id="partnerId"
        label="Makler-Id"
        name="partnerId"
        autoComplete="username"
        onChange={(e: any) => setPartnerId(e.target.value)}
        value={partnerId}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        disabled={isPartnerLoggedIn}
        fullWidth
        name="partnerPassword"
        label="Makler Passwort"
        type="password"
        id="partnerPassword"
        autoComplete="current-password"
        onChange={(e: any) => setPartnerPassword(e.target.value)}
        value={partnerPassword}
      />
    </>
  );

  const form = (
    <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
      {partnerLogin}
      {isPartnerLoggedIn ? employeeLogin : null}

      <Alert
        variant="error"
        isOpen={showError}
        onClose={() => setShowError(false)}
      >
        {errorText || "Makler-Id oder Passwort nicht korrekt!"}
      </Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.button}
      >
        Anmelden
      </Button>
      {isPartnerLoggedIn ? (
        <Button onClick={cancel} fullWidth variant="contained">
          Abbrechen
        </Button>
      ) : null}
    </form>
  );

  const loadingNafi = (
    <Loading>
      Bitte warten Sie,
      <br /> eine Anmeldung am NAFI System wird versucht...
    </Loading>
  );

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Anmelden als Makler
          </Typography>
          {isLoading && isExecutingNafi ? (
            loadingNafi
          ) : isLoading ? (
            <Loading />
          ) : (
            form
          )}
        </div>
      </Container>
    </>
  );
}
