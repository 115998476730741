import React from "react";
import { Container, CssBaseline, makeStyles } from "@material-ui/core";

import "./App.css";
import HeaderAnonymous from "./HeaderAnonymous";

import Login from "./Login";

const useStyles = makeStyles((theme: any) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function App() {
  const classes = useStyles();
  return (
    <>
      <HeaderAnonymous />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Login />
        </div>
      </Container>
    </>
  );
}

export default App;
