import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  center: {
    textAlign: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

interface ILoadingProps {
  children?: any;
}

const Loading = (props: ILoadingProps) => {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <CircularProgress className={classes.progress} />
      {props.children ? <div>{props.children}</div> : null}
    </div>
  );
};

export default Loading;
